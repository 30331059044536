@each $key, $value in $line-heights {
	.#{$atom-prefix}lh-#{$key} { line-height: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $line-heights {
			.#{$atom-prefix}lh-#{$key}-#{$breakpointName} { line-height: #{$value} !important; }
		};
	};
};
