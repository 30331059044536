html {
	font-size: 16px;
	font-family: var(--font-family-primary);
	line-height: 1.15;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	scroll-behavior: smooth;
}

html, body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: var(--color-text-default-1);
}

*, *:before, *:after {
	box-sizing: inherit;
}

p {
	margin: 0;
	padding: 0;
	font-family: var(--font-family-primary);
	line-height: 1.5;
}

body, h1, h2, h3, h4, h4, h5, h6 {
	margin: 0;
	padding: 0;
	line-height: 1.15;
}

a {
	background-color: transparent;
	text-decoration: none;
	color: inherit;
}

b, strong {
	font-weight: 600;
}

small {
	font-size: 80%;
}

button, input, select, textarea {
	font-size: 1rem;
	line-height: 1.15;
	margin: 0;
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

progress {
	display: block;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	height: auto;
	margin: 0;
}

[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

[hidden] {
	display: none;
}

[disabled] {
	cursor: not-allowed;
}

img, video, object {
	vertical-align: top;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

hr {
	display: block;
	width: 100%;
	height: 1px;

	margin: 0;

	border: none;
	background: var(--color-neutral-200);
}
