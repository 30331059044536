.#{$atom-prefix}gatf-r { grid-auto-flow: row !important }
.#{$atom-prefix}gatf-cl { grid-auto-flow: column !important }
.#{$atom-prefix}gatf-d { grid-auto-flow: dense !important }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}gatf-r-#{$breakpointName} { grid-auto-flow: row !important }
		.#{$atom-prefix}gatf-cl-#{$breakpointName} { grid-auto-flow: column !important }
		.#{$atom-prefix}gatf-d-#{$breakpointName} { grid-auto-flow: dense !important }
	};
};
