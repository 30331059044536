.#{$atom-prefix}dp-b   { display: block !important; }
.#{$atom-prefix}dp-f   { display: flex !important; }
.#{$atom-prefix}dp-g   { display: grid !important; }
.#{$atom-prefix}dp-il  { display: inline !important; }
.#{$atom-prefix}dp-ilb { display: inline-block !important; }
.#{$atom-prefix}dp-ilf { display: inline-flex !important; }
.#{$atom-prefix}dp-tb  { display: table !important; }
.#{$atom-prefix}dp-tbc { display: table-cell !important; }
.#{$atom-prefix}dp-n   { display: none !important; }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}dp-b-#{$breakpointName}   { display: block !important; }
		.#{$atom-prefix}dp-f-#{$breakpointName}   { display: flex !important; }
		.#{$atom-prefix}dp-g-#{$breakpointName}   { display: grid !important; }
		.#{$atom-prefix}dp-il-#{$breakpointName}  { display: inline !important; }
		.#{$atom-prefix}dp-ilb-#{$breakpointName} { display: inline-block !important; }
		.#{$atom-prefix}dp-ilf-#{$breakpointName} { display: inline-flex !important; }
		.#{$atom-prefix}dp-tb-#{$breakpointName}  { display: table !important; }
		.#{$atom-prefix}dp-tbc-#{$breakpointName} { display: table-cell !important; }
		.#{$atom-prefix}dp-n-#{$breakpointName}   { display: none !important; }
	};
};

.#{$atom-prefix}dp-b-hover   { &:hover { display: block !important; } }
.#{$atom-prefix}dp-f-hover   { &:hover { display: flex !important; } }
.#{$atom-prefix}dp-g-hover   { &:hover { display: grid !important; } }
.#{$atom-prefix}dp-il-hover  { &:hover { display: inline !important; } }
.#{$atom-prefix}dp-ilb-hover { &:hover { display: inline-block !important; } }
.#{$atom-prefix}dp-ilf-hover { &:hover { display: inline-flex !important; } }
.#{$atom-prefix}dp-tb-hover  { &:hover { display: table !important; } }
.#{$atom-prefix}dp-tbc-hover { &:hover { display: table-cell !important; } }
.#{$atom-prefix}dp-n-hover   { &:hover { display: none !important; } }
