.lo-pill {
	display: flex;
	align-items: stretch;

	> *:not(:first-child):not(:last-child) {
		border-radius: 0px;
	}

	> *:first-child {
		border-radius: 0px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	> *:last-child {
		border-radius: 0px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
