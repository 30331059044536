@each $key, $value in $radiuses {
	.#{$atom-prefix}bdrd-#{$key}    { border-radius: #{$value} !important; }
	.#{$atom-prefix}bdtlrd-#{$key}  { border-top-left-radius: #{$value} !important; }
	.#{$atom-prefix}bdtrrd-#{$key}  { border-top-right-radius: #{$value} !important; }
	.#{$atom-prefix}bdbtlrd-#{$key} { border-bottom-left-radius: #{$value} !important; }
	.#{$atom-prefix}bdbtrrd-#{$key} { border-bottom-right-radius: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $radiuses {
			.#{$atom-prefix}bdrd-#{$key}-#{$breakpointName}    { border-radius: #{$value} !important; }
			.#{$atom-prefix}bdtlrd-#{$key}-#{$breakpointName}  { border-top-left-radius: #{$value} !important; }
			.#{$atom-prefix}bdtrrd-#{$key}-#{$breakpointName}  { border-top-right-radius: #{$value} !important; }
			.#{$atom-prefix}bdbtlrd-#{$key}-#{$breakpointName} { border-bottom-left-radius: #{$value} !important; }
			.#{$atom-prefix}bdbtrrd-#{$key}-#{$breakpointName} { border-bottom-right-radius: #{$value} !important; }
		};
	};
};
