@each $key, $value in $spacings {
	.#{$atom-prefix}pd-#{$key} { padding: #{$value} !important; }
	.#{$atom-prefix}pdl-#{$key} { padding-left: #{$value} !important; }
	.#{$atom-prefix}pdr-#{$key} { padding-right: #{$value} !important; }
	.#{$atom-prefix}pdt-#{$key} { padding-top: #{$value} !important; }
	.#{$atom-prefix}pdbt-#{$key} { padding-bottom: #{$value} !important; }
	.#{$atom-prefix}pdv-#{$key} { padding-top: #{$value} !important; padding-bottom: #{$value} !important; }
	.#{$atom-prefix}pdh-#{$key} { padding-left: #{$value} !important; padding-right: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}pd-#{$key}-#{$breakpointName}   { padding: #{$value} !important; }
			.#{$atom-prefix}pdl-#{$key}-#{$breakpointName}  { padding-left: #{$value} !important; }
			.#{$atom-prefix}pdr-#{$key}-#{$breakpointName}  { padding-right: #{$value} !important; }
			.#{$atom-prefix}pdt-#{$key}-#{$breakpointName}  { padding-top: #{$value} !important; }
			.#{$atom-prefix}pdbt-#{$key}-#{$breakpointName} { padding-bottom: #{$value} !important; }
			.#{$atom-prefix}pdv-#{$key}-#{$breakpointName}  { padding-top: #{$value} !important; padding-bottom: #{$value} !important; }
			.#{$atom-prefix}pdh-#{$key}-#{$breakpointName}  { padding-left: #{$value} !important; padding-right: #{$value} !important; }
		};
	};
};
