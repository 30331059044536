.lo-container {
	display: grid;
	width: 100%;
	grid-template-columns: 12px minmax(0px, auto) 12px;
	grid-template-rows: auto;

	@each $breakpointName, $breakpointSize in $breakpoints {
		@media (min-width: $breakpointSize) {
			grid-template-columns: 1fr $breakpointSize - 40px 1fr;
		}
	};

	>* {
		grid-column: 2 / 3;
	}
}
