@each $key, $value in $spacings {
	.#{$atom-prefix}bt-#{$key} { bottom: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}bt-#{$key}-#{$breakpointName} { bottom: #{$value} !important; }
		};
	};
};
