@each $key, $value in $spacings {
	.#{$atom-prefix}mxh-#{$key} { max-height: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}mxh-#{$key}-#{$breakpointName} { max-height: #{$value} !important; }
		};
	};
};
