.#{$atom-prefix}zid-0 { z-index: 0 !important; }
.#{$atom-prefix}zid-1 { z-index: 1 !important; }
.#{$atom-prefix}zid-2 { z-index: 2 !important; }
.#{$atom-prefix}zid--1 { z-index: -1 !important; }
.#{$atom-prefix}zid-at { z-index: auto !important; }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}zid-0-#{$breakpointName} { z-index: 0 !important; }
		.#{$atom-prefix}zid-1-#{$breakpointName} { z-index: 1 !important; }
		.#{$atom-prefix}zid-2-#{$breakpointName} { z-index: 2 !important; }
		.#{$atom-prefix}zid--1-#{$breakpointName} { z-index: -1 !important; }
		.#{$atom-prefix}zid-at-#{$breakpointName} { z-index: auto !important; }
	};
};
