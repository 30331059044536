@each $key, $value in $colors {
	.#{$atom-prefix}cl-#{$key} { color: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $colors {
			.#{$atom-prefix}cl-#{$key}-#{$breakpointName} { color: #{$value} !important; }
		};
	};
};

@each $key, $value in $colors {
	.#{$atom-prefix}cl-#{$key}-hover {
		&:hover {
			color: #{$value} !important;
		}
	}
};
