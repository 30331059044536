[data-theme^="light"] {
    --color-primary-50:  hsl(231, 44%, 94%);
    --color-primary-100: hsl(232, 45%, 84%);
    --color-primary-200: hsl(231, 44%, 74%);
    --color-primary-300: hsl(230, 44%, 64%);
    --color-primary-400: hsl(231, 44%, 56%);
    --color-primary-500: hsl(231, 48%, 48%);
    --color-primary-600: hsl(232, 50%, 45%);
    --color-primary-700: hsl(232, 54%, 41%);
    --color-primary-800: hsl(233, 57%, 37%);
    --color-primary-900: hsl(235, 66%, 30%);
    --color-primary-1000:hsl(240, 100%, 16%);
    --color-primary-main:rgba(37, 37, 37, 1);
    --color-primary-light:rgba(80, 80, 80, 1);
    --color-primary-contrast:rgba(254, 254, 255, 1);


    --color-secondary-50:  hsl(187, 71%, 93%);
    --color-secondary-100: hsl(187, 71%, 82%);
    --color-secondary-200: hsl(187, 71%, 71%);
    --color-secondary-300: hsl(187, 71%, 59%);
    --color-secondary-400: hsl(187, 71%, 50%);
    --color-secondary-500: hsl(187, 100%, 42%);
    --color-secondary-600: hsl(187, 100%, 38%);
    --color-secondary-700: hsl(187, 100%, 33%);
    --color-secondary-800: hsl(187, 100%, 28%);
    --color-secondary-900: hsl(187, 100%, 20%);
    --color-secondary-1000:hsl(187, 100%, 11%);
    --color-secondary-main: rgba(253, 216, 53, 1);
    --color-secondary-light: rgba(255, 253, 231, 1);


    --color-base-50: white;
    --color-base-100: hsl(234, 27%, 90%);
    --color-base-200: hsl(234, 27%, 80%);
    --color-base-300: hsl(234, 27%, 70%);
    --color-base-400: hsl(234, 27%, 60%);
    --color-base-500: hsl(234, 27%, 50%);
    --color-base-600: hsl(234, 27%, 40%);
    --color-base-700: hsl(234, 27%, 28%);
    --color-base-800: hsl(234, 27%, 20%);
    --color-base-900: hsl(234, 27%, 15%);
    --color-base-1000: hsl(234, 27%, 10%);

    --color-positive-100: hsl(168, 75%, 95%);
    --color-positive-200: hsl(168, 75%, 90%);
    --color-positive-300: hsl(168, 75%, 83%);
    --color-positive-400: hsl(168, 76%, 48%);
    --color-positive-500: hsl(168, 76%, 45%);
    --color-positive-600: hsl(168, 76%, 41%);
    --color-positive-700: hsl(168, 66%, 25%);
    --color-positive-800: hsl(168, 66%, 20%);
    --color-positive-900: hsl(163, 80%, 15%);

    --color-negative-100: hsl(8, 85%, 97%);
    --color-negative-200: hsl(8, 85%, 93%);
    --color-negative-300: hsl(8, 85%, 89%);
    --color-negative-400: hsl(8, 78%, 67%);
    --color-negative-500: hsl(8, 78%, 61%);
    --color-negative-600: hsl(8, 78%, 55%);
    --color-negative-700: hsl(8, 68%, 38%);
    --color-negative-800: hsl(8, 68%, 34%);
    --color-negative-900: hsl(8, 68%, 30%);

    // --color-info-100: hsl(283, 90%, 96%);
    // --color-info-283: hsl(283, 90%, 92%);
    // --color-info-300: hsl(283, 90%, 88%);
    // --color-info-400: hsl(283, 40%, 55%);
    // --color-info-500: hsl(283, 40%, 50%);
    // --color-info-600: hsl(283, 40%, 45%);
    // --color-info-700: hsl(283, 66%, 28%);
    // --color-info-800: hsl(283, 66%, 25%);
    // --color-info-900: hsl(283, 66%, 22%);

    --color-warning-100: hsl(45, 80%, 95%);
    --color-warning-200: hsl(45, 80%, 90%);
    --color-warning-300: hsl(45, 80%, 85%);
    --color-warning-400: hsl(45, 90%, 55%);
    --color-warning-500: hsl(45, 90%, 48%);
    --color-warning-600: hsl(45, 90%, 45%);
    --color-warning-700: hsl(45, 90%, 31%);
    --color-warning-800: hsl(45, 90%, 27%);
    --color-warning-900: hsl(45, 90%, 27%);

    --color-grey-90: rgba(33, 33, 33, 1);
    --color-grey-80: rgba(66, 66, 66, 1);
    --color-grey-70: rgba(97, 97, 97, 1);
    --color-grey-60: rgba(117, 117, 117, 1);
    --color-grey-50: rgba(158, 158, 158, 1);
    --color-grey-40: rgba(189, 189, 189, 1);
    --color-grey-30: rgba(224, 224, 224, 1);
    --color-grey-20: rgba(238, 238, 238, 1);
    --color-grey-10: rgba(250, 250, 250, 1);

    --color-alert-red-a700: rgba(213, 0, 0, 1);
    --color-alert-amber-a700: rgba(255, 214, 0, 1);
    --color-alert-green-a700: rgba(0, 200, 83, 1);
    --color-alert-blue-a700: rgba(48, 79, 254, 1);
    --color-alert-red-bg: rgba(255, 241, 240, 1);
    --color-alert-amber-bg: rgba(255, 251, 230, 1);
    --color-alert-green-bg: rgba(246, 255, 237, 1);
    --color-alert-blue-bg: rgba(240, 245, 255, 1);
    --color-info-main: rgba(33, 150, 243, 1);

    --color-white: white;
    --color-black: black;

    --color-text-default-1: rgba(0, 0, 0, 1);
    --color-text-default-2: rgba(77, 77, 77, 1);
    --color-text-default-3: rgba(80, 80, 80, 1);

    --color-text-inv-1: hsla(0, 0%, 100%, .95);
    --color-text-inv-2: rgba(226, 226, 226, 0.95);

    --color-background-1: white;

    --color-text-primary-1: var(--color-primary-900);
    --color-text-primary-2: var(--color-primary-500);
    --color-text-primary-3: var(--color-primary-400);
    --color-text-secondary-1: var(--color-secondary-500);

    // Secondary Palette
    --color-shader: rgba(0, 0, 0 , 0.12);

    --color-page-background: linear-gradient(180deg, #F7F6FB 0%, #F7F6FB 100%);
}

// MUI
[data-theme^="light"] {
    --mui-background-default: black;
    --mui-background-paper: white;
    --mui-primary-main: black;
}

// Snows
[data-theme^="light"] {
    --snows-color: 150, 150, 150;
}

// Overall
[data-theme^="light"] {
    --logo-color: #1B134E;
}


// Header (Navbar)
[data-theme^="light"] {
    --header-background-color: rgba(255, 255, 255, 0.925);
    // --header-backdrop-filter: blur(8px);
    --header-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
    --header-color: black;
    --header-chain-background-color: #e7eaff;
    --header-chain-background-color-hover: rgba(209, 214, 248, 1); 
}

// Sidebar
[data-theme^="light"] {
    --sidebar-background-color: rgba(255, 255, 255, 0.692);
    --sidebar-backdrop-filter: blur(8px);
    --sidebar-border-right: none;

    --sidebar-menu-background-hover: linear-gradient(90deg, var(--color-primary-500) -200%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);;
    --sidebar-menu-background-active: linear-gradient(90deg, var(--color-primary-500) -200%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);

    --sidebar-menu-color: var(--color-text-default-1);
    --sidebar-menu-color-active: var(--color-primary-500);
    --sidebar-belt-color: var(--color-info-main);

    --sidebar-accordion-background: rgba(245, 245, 245, 1);
}

// Card
[data-theme^="light"] {
    --card-background-color: var(--color-base-50);
    --card-light-background: linear-gradient(108.51deg, rgba(255, 255, 255, 0.9) 3.43%, rgba(255, 255, 255, 0.9) 154.53%);
    --card-border: 1px solid var(--color-primary-main);
    --card-border-radius: 0px;
    --card-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05);

}

// Transparent Card
[data-theme^="light"] {
    --transparent-card-background: linear-gradient(108.51deg, rgba(255, 255, 255, 0.9) 3.43%, rgba(255, 255, 255, 0.1) 154.53%);
    --transparent-card-box-shadow: 10px 10px 30px rgba(187, 187, 255, 0.15);
    --transparent-card-backdrop-filter: blur(50px);
    --transparent-card-border: var(--card-border);
    --transparent-card-border-radius: var(--card-border-radius);

    --transparent-card-aura-background: var(--home-transparent-card-background);
    --transparent-card-aura-white-background: var(--home-transparent-card-background);
    --transparent-card-aura-shadow-color: rgba(187, 187, 255, 0.15);
}

// On Top Transparent Card
[data-theme^="light"] {
    --ontop-transparent-card-background: linear-gradient(108.51deg, rgba(205, 195, 248, 0.2) 3.43%, rgba(255, 255, 255, 0.15) 154.53%);
    --ontop-transparent-card-backdrop-filter: blur(1px);
    --ontop-transparent-card-border-radius: var(--card-border-radius);
    --ontop-transparent-card-wording-background: linear-gradient(108.51deg, rgba(205, 195, 248, 1) 3%, rgba(232, 231, 255, 1) 56%);
}

// Page Background
[data-theme^="light"] {
    --page-background-color: #F1F0EC;
    --page-background: var(--color-primary-main);
    // --page-background-color: #EAECF8;
    // --page-background: no-repeat url('./images/themes/light-background.png') fixed;
    
    // --funding-page-background: var(--color-page-background);
    --home-page-background: none;
    // --farm-v2-page-background: var(--color-page-background);
}

// Button
[data-theme^="light"] {
    --button-box-shadow-focus: var(--color-primary-100);

    --button-color-default: white;
    --button-background-default: var(--color-primary-main);
    --button-background-default-hover: var(--color-grey-80);
    --button-background-default-disable: var(--color-grey-20);
    --button-border-default: none;

    --button-color-secondary: var(--color-primary-main);
    --button-color-secondary-hover: white;
    --button-background-secondary: white;
    --button-background-secondary-hover: var(--color-primary-main);
    --button-background-secondary-disable: var(--color-grey-30);
    --button-border-secondary: 1px solid var(--color-primary-main);

    --button-color-tertiary: var(--color-black);
    --button-background-tertiary: transparent;
    --button-background-tertiary-hover: var(--color-grey-10);
    --button-background-tertiary-disable: var(--color-grey-20);
    --button-border-tertiary: 1px solid var(--color-grey-40);

    --button-color-ghost: var(--color-black);
    --button-color-ghost-disable: var(--color-grey-70);
    --button-background-ghost: transparent;
    --button-background-ghost-hover: var(--color-grey-20);
    --button-background-ghost-disable: var(--color-grey-30);
    --button-border-ghost: none;

    --button-color-outlined: var(--color-primary-main);
    --button-color-outlined-hover: var(--color-primary-main);
    --button-color-outlined-disable: var(--color-grey-70);
    --button-background-outlined: white;
    --button-background-outlined-hover: var(--color-grey-20);
    --button-background-outlined-disable: var(--color-grey-30);
    --button-border-outlined: 1px solid var(--color-primary-main);
}

// Input
[data-theme^="light"] {
    --input-outline-background: transparent;
    --input-outline-border: 1px solid rgb(207, 207, 207);
    --input-outline-border-hover: 1px solid rgb(187, 187, 187);
}


// Hr
[data-theme^="light"] {
    --hr-background: rgb(226, 226, 226);
    --hr-height: 1px;
}

// Vt
[data-theme^="light"] {
    --vt-background: rgb(226, 226, 226);
    --vt-width: 1px;
}

// Link
[data-theme^="light"] {
    --link-color: var(--color-warning-600);
    --link-color-hover: var(--color-grey-70);
}

// Badge
[data-theme^="light"] {
    --badge-text-color: rgb(0 128 53);
    --badge-background-color: rgba(231, 249, 213, 1);
    // --badge-text-color: var(--color-alert-green-a700);
    // --badge-background-color: var(--color-alert-green-bg);
}

// Slate
[data-theme^="light"] {
    --slate-background: hsl(231, 44%, 96%);
}

// Logging
[data-theme^="light"] {
    --logging-background: var(--color-grey-10);
}

// Modal
[data-theme^="light"] {
    --modal-overlay-background: linear-gradient(108.51deg, rgba(145, 133, 203, 0.2) 3.43%, rgba(255, 255, 255, 0.3) 154.53%);
    --modal-overlay-box-shadow: var(--card-box-shadow);
    --modal-content-background: white;
    --modal-content-border: 1px solid rgb(204, 204, 204);
}

// Scollbar
[data-theme^="light"] {
    --scrollbar-thumb-background: #606060;
    --scrollbar-track-shadow: var(--color-primary-200) 0px 0px 2px inset;
    --scrollbar-background: var(--page-background-color);
}

// Progress Circular
[data-theme^="light"] {
    --progress-circular-color: var(--color-secondary-100);
    --progress-circular-background-color: var(--color-primary-100);
}

//-------------------------
// Override MUI
//-------------------------
[data-theme^="light"] {
    // checkbox
    .MuiCheckbox.Mui-checked {
        .MuiSelect-icon,
        .MuiSvgIcon-root {
            fill: var(--color-primary-500);
        }
    }

    .MuiFormLabel-root.Mui-focused {
        label {
            color: var(--color-text-default-1);
        }
    }


    // Input
    .MuiInput-underline:before {
        border-bottom: 1px solid var(--color-text-default-1);
    }

    .MuiInput {
        .MuiSelect-icon,
        .MuiSvgIcon-root {
            fill: var(--color-text-default-1);
        }
    }

    .MuiOutlinedInput-root, .MuiSelect-outlined {
        background: var(--input-outline-background);
        border-radius: 8px;
    }

    .MuiTabs-root:not(.rei-tabs) {
        .MuiButtonBase-root {
            border-bottom: 1px solid var(--color-grey-20);

            &.Mui-selected {
                background-color: var(--color-secondary-50);
                font-weight: 600;
                // color: var(--button-color-default);
            }
        }
        .MuiTabs-indicator {
            background-color: var(--color-secondary-500);
        }
    }

    .MuiPopover-paper {
        // background-color: transparent;
        border-radius: 10px;
    }
}