.#{$atom-prefix}vtcal-t { vertical-align: top !important; }
.#{$atom-prefix}vtcal-bt { vertical-align: bottom !important; }
.#{$atom-prefix}vtcal-bl { vertical-align: baseline !important; }
.#{$atom-prefix}vtcal-md { vertical-align: middle !important; }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}vtcal-t-#{$breakpointName} { vertical-align: top !important; }
		.#{$atom-prefix}vtcal-bt-#{$breakpointName} { vertical-align: bottom !important; }
		.#{$atom-prefix}vtcal-bl-#{$breakpointName} { vertical-align: baseline !important; }
		.#{$atom-prefix}vtcal-md-#{$breakpointName} { vertical-align: middle !important; }
	};
};
