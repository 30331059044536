.card {
  position: absolute;   
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wordingContainer {
  padding: 10px 20px;
  background: var(--ontop-transparent-card-wording-background);
  border-radius: var(--card-border-radius);
}