.rei-tabs {
    background-color: transparent;

    .MuiButtonBase-root {
        min-height: 40px;
        min-width: 120px;
        padding: 0px 10px;
        background-color: var(--color-background-1);

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        border: 1px solid var(--color-text-default-3);
        opacity: 1;

        &.Mui-selected {
            border: 1px solid var(--color-primary-500);
            background-color: var(--color-primary-500);
            color: var(--button-color-default);
            font-weight: 600;
        }
    }

    .MuiTabs-indicator {
        display: none;
    }

    &.-small {
        min-height: 30px;

        .MuiButtonBase-root {
            min-height: 30px;
            min-width: 80px;
        }
    }

    &.-secondary {
        .MuiButtonBase-root {
            &.Mui-selected {
                border: 1px solid var(--color-secondary-500);
                background-color: var(--color-secondary-500);
                color: var(--button-color-default);
            }
        }
    }
}
