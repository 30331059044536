.#{$atom-prefix}tdcrt-udl { text-decoration: underline !important; }
.#{$atom-prefix}tdcrt-ovl { text-decoration: overline !important; }
.#{$atom-prefix}tdcrt-lt  { text-decoration: line-through !important; }
.#{$atom-prefix}tdcrt-n   { text-decoration: none !important; }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}tdcrt-udl-#{$breakpointName} { text-decoration: underline !important; }
		.#{$atom-prefix}tdcrt-ovl-#{$breakpointName} { text-decoration: overline !important; }
		.#{$atom-prefix}tdcrt-lt-#{$breakpointName}  { text-decoration: line-through !important; }
		.#{$atom-prefix}tdcrt-n-#{$breakpointName}   { text-decoration: none !important; }
	};
};
