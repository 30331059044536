@each $key, $value in $spacings {
	.#{$atom-prefix}mg-#{$key}   { margin: #{$value} !important; }
	.#{$atom-prefix}mgl-#{$key}  { margin-left: #{$value} !important; }
	.#{$atom-prefix}mgr-#{$key}  { margin-right: #{$value} !important; }
	.#{$atom-prefix}mgt-#{$key}  { margin-top: #{$value} !important; }
	.#{$atom-prefix}mgbt-#{$key} { margin-bottom: #{$value} !important; }
	.#{$atom-prefix}mgv-#{$key}  { margin-top: #{$value} !important; margin-bottom: #{$value} !important; }
	.#{$atom-prefix}mgh-#{$key}  { margin-left: #{$value} !important; margin-right: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}mg-#{$key}-#{$breakpointName}   { margin: #{$value} !important; }
			.#{$atom-prefix}mgl-#{$key}-#{$breakpointName}  { margin-left: #{$value} !important; }
			.#{$atom-prefix}mgr-#{$key}-#{$breakpointName}  { margin-right: #{$value} !important; }
			.#{$atom-prefix}mgt-#{$key}-#{$breakpointName}  { margin-top: #{$value} !important; }
			.#{$atom-prefix}mgbt-#{$key}-#{$breakpointName} { margin-bottom: #{$value} !important; }
			.#{$atom-prefix}mgv-#{$key}-#{$breakpointName}  { margin-top: #{$value} !important; margin-bottom: #{$value} !important; }
			.#{$atom-prefix}mgh-#{$key}-#{$breakpointName}  { margin-left: #{$value} !important; margin-right: #{$value} !important; }
		};
	};
};
