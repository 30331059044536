@each $key, $value in $spacings {
	.#{$atom-prefix}mnh-#{$key} { min-height: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-height: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}mnh-#{$key}-#{$breakpointName} { min-height: #{$value} !important; }
		};
	};
};
