@each $key, $value in $spacings {
	.#{$atom-prefix}h-#{$key} { height: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}h-#{$key}-#{$breakpointName} { height: #{$value} !important; }
		};
	};
};
