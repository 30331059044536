@import "src/styles/layout/config.scss";

.airdrop-locker-item-summary {
    display: 'flex';
    align-items: 'center';
    background: 'var(--card-background-color)';
    border-radius: 'var(--card-border-radius)';
    padding: 0;
    margin: 0;

    .layout {
        display: grid;
        grid-template-columns: 1fr 1.15fr 1fr 1fr auto;

        @media screen and (max-width: $sm) {
            grid-template-columns: 1fr;
        }
    }
}