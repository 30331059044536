.#{$atom-prefix}f-l { float: left !important; }
.#{$atom-prefix}f-r { float: right !important; }
.#{$atom-prefix}f-n { float: none !important; }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}f-l-#{$breakpointName} { float: left !important; }
		.#{$atom-prefix}f-r-#{$breakpointName} { float: right !important; }
		.#{$atom-prefix}f-n-#{$breakpointName} { float: none !important; }
	};
};
