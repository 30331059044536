@each $key, $value in $font-families {
	.#{$atom-prefix}ffml-#{$key} { font-family: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $font-families {
			.#{$atom-prefix}ffml-#{$key}-#{$breakpointName} { font-family: #{$value} !important; }
		};
	};
};
