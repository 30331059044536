@each $key, $value in $spacings {
	.#{$atom-prefix}t-#{$key} { top: #{$value} !important; }
};

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		@each $key, $value in $spacings {
			.#{$atom-prefix}t-#{$key}-#{$breakpointName} { top: #{$value} !important; }
		};
	};
};
