.#{$atom-prefix}lst-n { list-style: none !important }
.#{$atom-prefix}lst-d { list-style: disc !important }
.#{$atom-prefix}lst-dcm { list-style: decimal !important }
.#{$atom-prefix}lst-lwap { list-style: lower-alpha !important }
.#{$atom-prefix}lst-apap { list-style: upper-alpha !important }

@each $breakpointName, $breakpointSize in $breakpoints {
	@media (min-width: $breakpointSize) {
		.#{$atom-prefix}lst-n-#{$breakpointName} { list-style: none !important }
		.#{$atom-prefix}lst-d-#{$breakpointName} { list-style: disc !important }
		.#{$atom-prefix}lst-dcm-#{$breakpointName} { list-style: decimal !important }
		.#{$atom-prefix}lst-lwap-#{$breakpointName} { list-style: lower-alpha !important }
		.#{$atom-prefix}lst-apap-#{$breakpointName} { list-style: upper-alpha !important }
	};
};
